import {$, $$} from '../General/Aliases';

$$('[data-embed-youtube]').forEach(node => {
    const iframe = document.createElement("iframe");
    const embedId = node.getAttribute("data-embed-id");
    const width = node.getAttribute("data-width");
    iframe.src = `https://www.youtube-nocookie.com/embed/${embedId}?autohide=1&controls=1&enablejsapi=1`;
    iframe.width = width;
    iframe.classList.add('e-media-embed__iframe');
    iframe.setAttribute('allowfullscreen', 'true');
    node.on('click', () => {
       node.appendChild(iframe);
    });
});