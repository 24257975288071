// CLASS ToggleSelect
//# custom select control element

export default class ToggleSelect {
  constructor(node) {
    this.node = node;
    this.marker = node.querySelector('[data-toggle-select-marker]');
    this.value = null;
    this.activeIndex = 0;
    this.options = node.querySelectorAll('[data-toggle-select-option]');
    this.options[0].classList.add('-active');
    this.watch();
  }
  selectOption(option, index) {
    if (index != this.activeIndex) {
      this.options[this.activeIndex].classList.remove('-active');
      option.classList.add('-active');
      this.activeIndex = index;
      this.marker.dataset.toggleSelectMarker = index + 1;
    }
  }
  watch() {
    const self = this;
    this.marker.classList.add(`-length${this.options.length}`);
    this.options.forEach((option,i) => {
      option.onclick = e => {
        self.selectOption(option, i);
      };
    });
  }
}