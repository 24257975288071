import { $, $$ } from "../General/Aliases";
import { Glide, glideArrowDisabler } from "../Classes/Glide";

console.log("LOG:  ~ $$ ~ slider import");
const elements = $$("[data-glide-type='media-single']");
elements.forEach((node) => {
  if (elements.length >= 1) {
    console.log("LOG:  ~ $$ ~ slider init");
  
    const el = {
      node: node,
      glide: {},
    };
    el.glide = new Glide(el.node, {
      type: "slider",
      gap: 20,
      rewind: false,
      dragThreshold: 40,
      bound: true,
      perView: 1,
      animationDuration: 400,
      breakpoints: {
        1050: {
          perView: 2,
          gap: 20,
        },
        600: {
          perView: 1,
          gap: 20,
        },
      },
    }).mount({ glideArrowDisabler });
  }
  // node.$$('[data-product-slider-dosage-nav]').forEach((nav, i) => {
  //   el.dosageNavs[i] = {
  //     node: nav,
  //     value: nav.getAttribute('data-product-slider-dosage-nav'),
  //     firstItemIndex: null,
  //   };
  //   const nv = el.dosageNavs[i];
  //   const firstDosageItem = el.node.$(`[data-product-slider-dosage='${nv.value}']`);
  //   if (firstDosageItem) {
  //     nv.firstItemIndex = Array.from(firstDosageItem.parentNode.children).indexOf(firstDosageItem);
  //     nv.node.on('click', event => {
  //       el.glide.go(`=${nv.firstItemIndex > 0 ? nv.firstItemIndex : 0}`);
  //     });
  //   }
  // });
  // el.glide.go(`=${el.dosageNavs[4].firstItemIndex}`);
  // onGlideRun();
  function onGlideRun() {
    console.log("LOG:  ~ onGlideRun ~ onGlideRun");
    // el.spectrum.className = `m-menu-cards-slider__spectrum -blockpos0`;
    // let activeNavIndex = 0;
    // for (let [index, nv] of el.dosageNavs.entries()) {
    //   if (nv.firstItemIndex && el.glide.index >= nv.firstItemIndex) {
    //     el.spectrum.className = `m-menu-cards-slider__spectrum -blockpos${index}`;
    //     activeNavIndex = index;
    //   }
    // }
    // for (let [index, nv] of el.dosageNavs.entries()) {
    //   if (index == activeNavIndex) {
    //     nv.node.classList.add('-active');
    //   } else {
    //     nv.node.classList.remove('-active');
    //   }
    // }
  }
  // el.glide.on("run", (event) => {
  //   onGlideRun();
  // });
});
