// CLASS Toggle
//# custom select control element for switching light/dark mode

import { $ } from "../General/Aliases";

export default class Toggle {
  constructor(node) {
    this.node = node;
    this.value = sessionStorage.getItem("contrastMode");
    this.checkbox = this.node.querySelector("[type=checkbox]");
    this.init();
    this.checkbox.checked = this.value === "true";
    this.watch();
  }
  toggleClicked() {
    if (this.value === "true") {
      $("body").classList.remove("-contrast-mode");
      this.setSessionsStorage("false");
      this.checkbox.checked = false;
    } else {
      $("body").classList.add("-contrast-mode");
      this.setSessionsStorage("true");
      this.checkbox.checked = true;
    }
  }
  init() {
    if (this.value === "true") {
      $("body").classList.add("-contrast-mode");
    } else {
      $("body").classList.remove("-contrast-mode");
    }
  }
  setSessionsStorage(value) {
    sessionStorage.setItem("contrastMode", value);
    this.value = value;
  }
  watch() {
    const self = this;
    this.node.onclick = () => {
      self.toggleClicked();
    };
  }
}
