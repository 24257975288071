import { $, $$ } from "../General/Aliases";
import BurgerMenu from "../Classes/BurgerMenu";
import SmoothHashLinks from "../Classes/SmoothHashLinks";
import ScrollSensitive from "../Classes/ScrollSensitive";
import LinkTo from "../Classes/LinkTo";
import NavigationHover from '../Classes/NavigationHover';
import ToggleSelect from "../Classes/ToggleSelect";
import Toggle from "../Classes/Toggle";
import { Accordion, AccordionGroup, toggleEvent } from "../Classes/Accordion";
import Modal from "../Classes/Modal";
import smoothscroll from "smoothscroll-polyfill";

new BurgerMenu();
new SmoothHashLinks(120);
smoothscroll.polyfill();
new LinkTo();
// new Toggle($("[data-toggle-contrast-mode]"));
$$("[data-scroll-sensitive]").forEach((el) => {
  new ScrollSensitive(el);
});
// $$('[data-navigation-hover]').forEach(el => {
//   new NavigationHover(el);
// });
let toggleSelects = {};
$$("[data-toggle-select]").forEach((el) => {
  toggleSelects[el.id] = new ToggleSelect(el);
});
let accordionGroups = {};
$$('[data-acc-el="group"]').forEach((el) => {
  console.log("LOG:  ~ $$ ~ el", el);
  accordionGroups[el.id] = new AccordionGroup(el);
});
let accordionSingles = {};
$$('[data-acc="single"]').forEach((el) => {
  console.log("LOG:  ~ $$ ~ el", el);
  accordionSingles[el.id] = new Accordion(el);
});
let modals = {};
$$("[data-modal]").forEach((el) => {
  modals[el.id] = new Modal(el);
});

$$("[data-to-top]").forEach((el) => {
  el.on("click", function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
});

$("body").classList.remove("u-no-transition");
